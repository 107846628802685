// ==========================================================================
// Nav
// ==========================================================================

document.addEventListener(
   "DOMContentLoaded", () => {
      new Mmenu( "#ithotdesknav", {
         "extensions": [
            "position-right",
            "fx-menu-slide"
         ],
         "iconPanels": true,
         "navbars": [
            {
               "position": "bottom",
               "content": [
                     "<a class='fa fa-fw fa-linkedin' href='https://www.linkedin.com/company/587628'></a>",
                     "<a class='fa fa-facebook' href='https://www.facebook.com/IT-Hotdesk-101522998179694'></a>",
                     "<a class='fa fa-fw fa-twitter ' href='https://twitter.com/ithotdesk'></a>",
               ]
            }
         ]
      });
   }
);


